@mixin root {
    :root {
        --bs-body-font-size: 0.85rem;
        --bs-body-font-family: "Inter", sans-serif;
        @supports (font-variation-settings: normal) {
            --bs-body-font-family: "Inter var", sans-serif;
        }
        --white: #fff;
        --bs-black: #000;
        --gray: #e0e0e0;
        --dark-gray: #969696;
        --bs-secondary-rgb: 108, 117, 125;
        --filter-invert: invert(0);
        // main
        --html-color: var(--bs-gray-200);
        --body-background-color: var(--bs-gray-200);
        --body-text-color: #212529;
        --scrollbar-thumb-color: rgba(0, 0, 0, 0.16);
        // avatar
        --avatar-bg: rgba(0, 0, 0, 0.85);
        --avatar-color: rgba(255, 255, 255, 0.85);
        // alerts
        --alert-secondary-color: #41464b;
        --alert-secondary-background-color: #e2e3e5;
        --alert-secondary-border-color: #d3d6d8;
        // alerts dark
        --alert-danger-dark-color: #f8d7da;
        --alert-danger-dark-background-color: #dc3545;
        --alert-danger-dark-border-color: #e35d6a;
        // blockquotes
        --blockquotes-background-color: #f6f6f6;
        --blockquotes-border-color: var(--bs-gray-200);
        --blockquotes-p-color: var(--bs-gray-900);
        --blockquotes-footer-color: var(--bs-gray-600);
        // btns
        --btn-text-bg-rgb: 226, 227, 229;
        --btn-text-color: var(--bs-gray-700);
        --btn-text-color-rgb: 73, 80, 87;
        --btn-close-background-image: transparent
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
            center/1em auto no-repeat;
        --btn-editor-background: #f1f1f1;
        --btn-editor-background-hover: #e2e2e2;
        --btn-secondary-background: #f1f1f1;
        --btn-secondary-background-hover: #e2e2e2;
        // cards
        --card-background-color: var(--bs-white);
        --card-border-color: var(--bs-gray-200);
        --card-title-color: var(--body-text-color);
        --card-text-color: var(--body-text-color);
        --card-box-shadow-color: 0 0.5px 0 0.5px #e0e0e0;
        // card-post
        --card-post-date-color: var(--bs-gray-700);
        --card-post-views-color: var(--bs-gray-700);
        // code
        --code-background-color: rgba(0, 0, 0, 0.85);
        --code-border-color: #d63384;
        --code-p-color: #d63384;
        --code-footer-color: #d63384;
        // dropdowns
        --dropdown-menu-title-background-color: #ffffff;
        --dropdown-menu-background-color: #ffffff;
        --user-dropdown-item-color: var(--bs-gray-700);
        --user-dropdown-item-background-color-hover: var(--bs-gray-200);
        --user-dropdown-item-color-hover: var(--bs-gray-700);
        --user-dropdown-devider-border-color: var(--bs-gray-200);
        // header
        --header-background-color: rgba(233, 236, 239, 0.8);
        --header-burger-btn-background: var(--bs-gray-200);
        --header-burger-btn-svg: var(--bs-gray-600);
        // footer
        --footer-border-color: var(--bs-gray-400);
        // forms
        --form-control-background-color: var(--bs-white);
        --form-control-color: var(--bs-gray-900);
        --form-control-placeholder-color: var(--bs-gray-600);
        --form-control-border-color: #eff0f1;
        --form-control-background-focus-color: var(--bs-white);
        --form-control-border-focus-color: var(--bs-gray-400);
        --form-control-focus-color: var(--bs-gray-900);
        --form-control-readonly-backgroud: var(--bs-gray-200);
        --form-control-readonly-border: var(--bs-gray-200);
        --form-control-readonly-color: var(--bs-gray-500);
        // --form-editor-container-bg: rgba(233, 236, 239, 0.8);
        --form-editor-container-bg: rgba(233, 236, 239, 0.8);
        --form-editor-container-bg-hex: #edf0f2;
        // list
        --list-group-item-background: var(--bs-white);
        --list-group-item-border-color: var(--bs-gray-200);
        --list-group-item-color: var(--bs-gray-900);
        --list-group-item-hover-background: var(--bs-gray-100);
        --list-group-item-hover-border-color: var(--bs-gray-100);
        --list-group-item-hover-color: var(---bs-gray-700);
        // polls
        --progress-bar-bg: 140, 140, 140;
        --poll-percent: 0, 0, 0;
        // modals
        --modal-background-color: var(--bs-white);
        --modal-backdrop-color: var(--bs-black);
        --modal-header-color: var(--body-text-color);
        --modal-usermenu-background: var(--body-background-color);
        // navigation
        --mobile-list-item: var(--bs-gray-600);
        --navigation-bar-link-color: var(--bs-gray-700);
        --navigation-bar-link-color-hover: var(--bs-gray-500);
        --navigation-bar-user-dropdown-icon-color: var(--bs-gray-700);
        --navigation-bar-user-dropdown-icon-background-color: var(--bs-gray-400);
        --navigation-bar-user-dropdown-icon-color-hover: var(--bs-gray-900);
        --user-menu-item-color: var(--bs-gray-700);
        --user-menu-item-hover-color: var(--bs-gray-900);
        --user-menu-item-hover-background-color: var(--bs-gray-400);
        // skeleton
        --skeleton-background: linear-gradient(
                to right,
                rgb(143, 142, 141) 0%,
                rgb(237, 235, 233) 50%,
                rgb(143, 142, 141) 100%
            )
            0 0 / 200% 100% rgb(243, 242, 241);
    }
}

@mixin dark-root {
    :root {
        --bs-body-font-size: 0.85rem;
        --bs-body-font-family: "Inter", sans-serif;
        @supports (font-variation-settings: normal) {
            --bs-body-font-family: "Inter var", sans-serif;
        }
        --white: #fff;
        --bs-black: #000;
        --gray: #e0e0e0;
        --dark-gray: #969696;
        --bs-secondary-rgb: 33, 37, 41;
        --filter-invert: invert(1);
        // main
        --html-color: var(--bs-black);
        --body-background-color: var(--bs-black);
        --body-text-color: #e0e0e0;
        --scrollbar-thumb-color: rgba(255, 255, 255, 0.16);
        // avatar
        --avatar-bg: rgba(255, 255, 255, 0.85);
        --avatar-color: rgba(0, 0, 0, 0.85);
        // alerts
        --alert-secondary-color: var(--bs-gray-700);
        --alert-secondary-background-color: rgba(255, 255, 255, 0.16);
        --alert-secondary-border-color: rgba(255, 255, 255, 0.16);
        // alerts dark
        --alert-danger-dark-color: #f8d7da;
        --alert-danger-dark-background-color: #dc3545;
        --alert-danger-dark-border-color: #e35d6a;
        // blockquotes
        --blockquotes-background-color: #212121;
        --blockquotes-border-color: rgba(255, 255, 255, 0.06);
        --blockquotes-p-color: var(--bs-gray-100);
        --blockquotes-footer-color: var(--bs-gray-600);
        // btns
        --btn-text-bg-rgb: 76, 77, 79;
        --btn-text-color: var(--bs-gray-100);
        --btn-text-color-rgb: 248, 249, 250;
        --btn-close-background-image: transparent
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
            center/1em auto no-repeat;
        --btn-editor-background: #414144;
        --btn-editor-background-hover: #717171;
        --btn-secondary-background: #414144;
        --btn-secondary-background-hover: #616161;
        // cards
        --card-background-color: #111215;
        --card-border-color: #111215;
        --card-title-color: var(--body-text-color);
        --card-text-color: var(--body-text-color);
        --card-box-shadow-color: 0 0.5px 0 0.5px #303030;
        // card-post
        --card-post-date-color: var(--bs-gray-300);
        --card-post-views-color: var(--bs-gray-300);
        // code
        --code-background-color: rgba(40, 40, 40, 0.555);
        --code-border-color: #d63384;
        --code-p-color: #d63384;
        --code-footer-color: #d63384;
        // dropdowns
        --dropdown-menu-title-background-color: #2f2f32;
        --dropdown-menu-background-color: #2f2f32;
        --user-dropdown-item-color: var(--bs-gray-400);
        --user-dropdown-item-background-color-hover: rgba(255, 255, 255, 0.06);
        --user-dropdown-item-color-hover: var(--bs-gray-200);
        --user-dropdown-devider-border-color: var(--bs-gray-700);
        // header
        --header-background-color: rgba(0, 0, 0, 0.8);
        --header-burger-btn-background: rgba(255, 255, 255, 0.06);
        --header-burger-btn-svg: #e0e0e0;
        // footer
        --footer-border-color: rgba(255, 255, 255, 0.06);
        // forms
        --form-control-background-color: var(--bs-gray-900);
        --form-control-color: var(--bs-white);
        --form-control-placeholder-color: var(--bs-gray-600);
        --form-control-border-color: var(--bs-gray-900);
        --form-control-background-focus-color: var(--bs-gray-900);
        --form-control-border-focus-color: var(--bs-gray-800);
        --form-control-focus-color: var(--bs-white);
        --form-control-readonly-backgroud: var(--bs-gray-700);
        --form-control-readonly-border: var(--bs-gray-700);
        --form-control-readonly-color: var(--bs-gray-500);
        --form-editor-container-bg: rgba(255, 255, 255, 0.2);
        --form-editor-container-bg-hex: #414144;
        // list
        --list-group-item-background: rgba(255, 255, 255, 0.06);
        --list-group-item-border-color: var(--bs-gray-700);
        --list-group-item-color: var(--bs-gray-300);
        --list-group-item-hover-background: rgba(255, 255, 255, 0.2);
        --list-group-item-hover-border-color: var(--bs-gray-600);
        --list-group-item-hover-color: var(--bs-gray-100);
        // polls
        --progress-bar-bg: 100, 100, 100;
        --poll-percent: 255, 255, 255;
        // modals
        --modal-background-color: #111215;
        --modal-backdrop-color: rgba(255, 255, 255, 0.4);
        --modal-header-color: var(--body-text-color);
        --modal-usermenu-background: var(--body-background-color);
        // navigation
        --mobile-list-item: #707070;
        --navigation-bar-link-color: var(--bs-gray-400);
        --navigation-bar-link-color-hover: var(--bs-gray-100);
        --navigation-bar-user-dropdown-icon-color: var(--bs-gray-400);
        --navigation-bar-user-dropdown-icon-background-color: var(--bs-gray-600);
        --navigation-bar-user-dropdown-icon-color-hover: var(--bs-gray-400);
        --user-menu-item-color: var(--bs-gray-400);
        --user-menu-item-hover-color: var(--bs-gray-200);
        --user-menu-item-hover-background-color: rgba(255, 255, 255, 0.2);
        // skeleton
        --skeleton-background: linear-gradient(to right, rgb(28, 28, 28) 0%, rgb(50, 50, 50) 50%, rgb(28, 28, 28) 100%)
            0 0 / 200% 100% rgb(70, 70, 70);
    }
}
